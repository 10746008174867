import React, { Component } from 'react'
import Img from "gatsby-image"

class EmbedVideo extends Component {
    state = {
        showVideo: false
    }

    revealVideo = () => {
        this.setState({ showVideo: true })
    }

    render() {
        const { showVideo } = this.state
        var vid = null

        if ( this.state.showVideo ) {
            vid = (
            
                 <iframe
                    src={this.props.videoSrcURL+"?autoplay=1"}
                    title={this.props.videoTitle}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                    style={{position: 'absolute', top: '0', left: '0', height: '100%', width: '100%', display: showVideo ? 'block' : 'none'}}
                />
            
            )
       }


        return (

            <div className={showVideo ? 'video-lock' : 'video'} onClick={this.revealVideo}>
            
                <Img
                    className="video-thumbnail"
                    fluid={this.props.thumbnail?.node.childImageSharp.fluid}
                    alt="Video Thumbnail"
                    style={{opacity: showVideo ? '0' : '1'}}
                />    

                <div className={showVideo ? 'hide' : 'video-item-overlay'}>
                    <svg className="circle-fill">
                        <circle cx="41" cy="41" r="37" stroke="#fff"></circle>
                        <polygon stroke="#fff" points="32,25 32,58 60,42"></polygon>
                    </svg>
                </div>

                {vid}
                
            </div>
            
        )
    }

/*
const EmbedVideo = ({ videoSrcURL, videoTitle, imageData, ...props }) => (
  <div className="video" onClick={showVideo = !showVideo}>
      <Img
        fluid={imageData}
        alt="Gatsby Docs are awesome"
        style={{display: showVideo ? 'none' : 'block'}}
      />
    <iframe
        visible={showVideo}
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        style={{height: '300px', width: '100%', display: showVideo ? 'block' : 'none'}}
    />
  </div>
)
*/

}

export default EmbedVideo