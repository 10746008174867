import React from "react"
import LayoutRegular from "../components/layoutRegular"
import EmbedVideo from "../components/embedVid"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"


export default function Music() {
  const { yticon, spotifyIcon, appleMusicIcon, videoThumbs, cds } = useStaticQuery(
    graphql`
      query {
        yticon: file(relativePath: { eq: "yt_icon_mono_dark.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        },
        spotifyIcon: file(relativePath: { eq: "assets/spotify_logo.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        },
        appleMusicIcon: file(relativePath: { eq: "assets/apple_music_logo.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        },
        videoThumbs: allFile(filter: { relativeDirectory: {eq: "musicThumbs"}}, sort: {order: ASC, fields: name}) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        cds: allFile(filter: { relativeDirectory: {eq: "cds"}}, sort: {order: ASC, fields: name}) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )

  

  // Single Image Data
  const imageData = videoThumbs.edges

  // console.log("hier: " + JSON.stringify(videoThumbs.edges[0]));
  // console.log("hier cds: " + JSON.stringify(cds));

  const cdsData = [
    {url: "https://www.ars-produktion.de/Benjamin_Engeli_Johannes_Brahms/topic/Neuheiten/shop_art_id/580/tpl/shop_article_detail"},
    {url: "https://solo-musica.de/benjamin-engeli-beethoven-klaviersonaten/"},
    {url: "https://www.jpc.de/jpcng/classic/detail/-/art/francis-poulenc-kammermusik-fuer-blaeser/hnum/9512358"},
    {url: "https://www.amazon.de/Transatlantiques-Gershwin-Piano-Quartet/dp/B076JL7LT4"},
    {url: "https://www.jpc.de/jpcng/classic/detail/-/art/hymne-a-la-beaute/hnum/8318284"},
    {url: "https://shop.vokalmusik.ch/shop/cd/"},
    {url: "https://www.ars-produktion.de/Beethoven_Kamilla_SchatzBenjamin_Engeli/topic/Neuheiten/shop_art_id/586/tpl/shop_article_detail"},
    {url: "https://www.challengerecords.com/products/15163548634579"},
    {url: "https://www.ars-produktion.de/Zurich_Ensemble_KirchnerMessiaenM_ller/topic/Suchergebnis/shop_art_id/521/tpl/shop_article_detail"},
    {url: "https://shop.vokalmusik.ch/shop/cd/#cc-m-product-13353223323"},
    {url: "https://www.musiques-suisses.ch/mussui-page/album/?id=297"},
    {url: "https://www.paladino.at/cds/pmr-0036"},
    {url: "https://www.claves.ch/products/lisa-schatzman-reminiscences"},
    {url: "https://neos-music.com/?language=english&page=output.php?content=Kuenstler/Schneid_Tobias_PM.php"},
    {url: "https://medienarchiv.zhdk.ch/sets/62233a0b-4159-49cb-91fb-7e523ad57661"},
    {url: "https://www.musiques-suisses.ch/mussui-page/album/?id=299"},
    {url: "https://en.schott-music.com/shop/edgard-varese-ameriques-morton-feldman-piece-for-four-pianos-five-pianos-no261745.html"},
  ]

  

  const pageSEO = {title: "Music", description: "Listen to Benjamin Engelis latest music and watch his piano music videos"}

  return (
    <LayoutRegular>
      <SEO title={pageSEO.title} description={pageSEO.description} />
      <h1>Music</h1>
      <div className="divider"></div>
      <h2 id="videos" style={{marginTop: '2rem', paddingTop: '1rem'}}>Videos</h2>
        <div className="grid-container">

            <EmbedVideo
              videoSrcURL="https://www.youtube-nocookie.com/embed/PgnnOAeXug4"
              videoTitle="Gabriel Faure: Nocturne No. 1 in E flat minor op. 33"
              thumbnail={imageData[8]}
            />

            <EmbedVideo
              videoSrcURL="https://www.youtube-nocookie.com/embed/kWffu4RvVFI"
              videoTitle="Frederic Chopin: Polonaise-Fantaisie A flat major op. 61"
              thumbnail={imageData[0]}
            />

            <EmbedVideo
              videoSrcURL="https://www.youtube-nocookie.com/embed/yea0CdnYNg8"
              videoTitle="Schubert: Piano Sonata C Minor D 958 - 1st movement"
              thumbnail={imageData[1]}
            />

            <EmbedVideo
              videoSrcURL="https://www.youtube-nocookie.com/embed/FtmSAzz6O3w"
              videoTitle="Rachmaninoff: Vocalise"
              thumbnail={imageData[2]}
            />

            <EmbedVideo
              videoSrcURL="https://www.youtube-nocookie.com/embed/NGT7cu38XPY"
              videoTitle="Ensemble Kandinsky, live @ Klang Musiktage auf Schloss Meggenhorn 2018"
              thumbnail={imageData[3]}
            />

            <EmbedVideo
              videoSrcURL="https://www.youtube-nocookie.com/embed/6YTWPcHWRqE"
              videoTitle="Ribal Molaeb & Benjamin Engeli│Frank Bridge - Two Pieces for Viola and Piano"
              thumbnail={imageData[4]}
            />

            <EmbedVideo
              videoSrcURL="https://www.youtube-nocookie.com/embed/MBgm2tGXaBk"
              videoTitle="Tecchler Trio ARD-Musikwettbewerb 2007"
              thumbnail={imageData[5]}
            />

            <EmbedVideo
              videoSrcURL="https://www.youtube-nocookie.com/embed/0ayY6lc0FJg"
              videoTitle="J. Brahms Klavierquartett op. 60, Andante"
              thumbnail={imageData[6]}
            />

            <EmbedVideo
              videoSrcURL="https://www.youtube-nocookie.com/embed/H2pGPxkRsK0"
              videoTitle="Gershwin Piano Quartet - Rhapsody in Blue - Trip to China"
              thumbnail={imageData[7]}
            />


        </div>
      <a href="https://www.youtube.com/channel/UCkfcXsPOCMLAse8CzKdHmig" target="_blank" rel="noreferrer">
        <div className="callout endOfPageMargin">
          <div className="icon-container">
            <Img
              fluid={yticon.childImageSharp.fluid}
              alt="YouTube Icon"
            />
          </div>
          Visit Benjamin Engelis YouTube Channel
        </div>
      </a>
        
        <h2 id="cds" style={{marginTop: '2rem', paddingTop: '1rem'}}>CDs</h2>
        
        <div className="grid-container-cd">
          {cds.edges.map((img, i) => (
            <div key={'cd'+i} style={{display: "block"}}>
              <a href={cdsData[i].url} target="_blank" rel="noreferrer">
                <Img fluid={img.node.childImageSharp.fluid} style={{height: '100%' }} />
              </a>
            </div>
          ))}
        </div>
        <div style={{display: 'inline'}}>
          <a href="https://open.spotify.com/artist/4niKWOS6oVRePBPJj8GMuK" target="_blank" rel="noreferrer">
            <div className="callout callout-spotify" style={{width: '80px', height: '25px', display: 'inline-block', marginRight: '2rem'}}>
              <div className="icon-container" style={{width: '100%', height: '100%'}}>
                <Img
                  fluid={spotifyIcon.childImageSharp.fluid}
                  alt="Spotify Icon"
                />
              </div>
            </div>
          </a>

          <a href="https://music.apple.com/ch/artist/benjamin-engeli/390898578" target="_blank" rel="noreferrer">
            <div className="callout callout-apple" style={{width: '120px', height: '25px', display: 'inline-block'}}>
              <div className="icon-container" style={{width: '100%', height: '100%'}}>
                <Img
                  fluid={appleMusicIcon.childImageSharp.fluid}
                  alt="Apple Music Icon"
                />
              </div>
            </div>
          </a>
        </div>
        <div className="endOfPageMargin"></div>
        </LayoutRegular>
  )
}
